import {
  Box, Typography, darken, useTheme,
} from '@mui/material';
import React from 'react';
import { Emphasis } from '../components/Emphasis';
import './styles.css';

export default function CurrentReality() {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        padding: '30px',
        paddingTop: '20px',
        width: '100vw',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px',
        backgroundColor: palette.primary.dark,
      }}
    >
      <Box sx={{ textAlign: 'center', color: darken(palette.success.dark, 0.8), maxWidth: '50vw' }}>
        <Typography variant="h4" color={palette.secondary.main} mb={1}>
          <Emphasis>Parabéns seu pagamento foi confirmado!</Emphasis>
        </Typography>
        <Typography variant="h5" color={palette.secondary.main}>
          Em poucos minutos você vai receber as informações de acesso no seu e-mail.
        </Typography>
      </Box>
    </Box>
  );
}
